<template>
	<div>
		<img :src="$img(this.advs)" style="width: 100%;">
		<div class="goods-list" v-loading="loading">
			<div class="list-wrap">
				<div class="list-right">
					<div class="cargo-list" v-if="cargoList.length">
						<div class="goods-info">
							<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })"
									@error="imageError(index)" />
								<div class="price-wrap">
									<div class="price">
										<p>积分</p>
										{{ item.integ_price }}
									</div>
									<div class="market-price">￥{{ item.discount_price }}</div>
								</div>
								<div class="goods-name">{{ item.goods_name }}</div>
								<div class="sale-num">
									<p>{{ item.sale_num || 0 }}</p>人付款
								</div>
								<div class="shop_name">{{item.site_name}}</div>
								<div class="saling">
									<div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
									<div class="is_own" v-if="item.is_own == 1">自营</div>
									<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
								</div>
							</div>
						</div>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
								:current-page.sync="currentPage" :page-size.sync="pageSize"
								@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
								hide-on-single-page></el-pagination>
						</div>
					</div>
					<div class="empty" v-else>
						<div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Topicgoods,goodsSkuPage
	} from "@/api/goods/goods"
	import {
		adList
	} from "@/api/website"
	
	export default {
		data: () => {
			return {
				cargoList: [],
				currentPage: 1,
				pageSize: 15,
				total: 0,
				loading: true,
				menus_id: 0,
				advs: ''
			}
		},
		created() {

			// this.getTopicgoods()

			adList({
					keyword: "INTEGS_BRAND"
				})
				.then(res => {
					var adList = res.data.adv_list
					this.advs = adList[0]['adv_image']
				})
				.catch(err => {
					this.loading = false
				})
				
				goodsSkuPage({
					page_size: this.pageSize,
					page: this.currentPage,
					integ:1
				})
					.then(res => {
						const {
							count,
							page_count,
							list,
							topic_adv
						} = res.data
						this.total = count
						this.cargoList = list
						this.loading = false
						
					})
					.catch(err => {})
					
		},
		computed: {},
		methods: {

			handlePageSizeChange(size) {
				this.pageSize = size
				this.getTopicgoods()
			},
			handleCurrentPageChange(page) {
				this.currentPage = page
				this.getTopicgoods()
			},
			getTopicgoods() {

				Topicgoods({
						topic_id: this.menus_id,
						page_size: this.pageSize,
						page: this.currentPage,
					})
					.then(res => {

						const {
							count,
							page_count,
							list,
							topic_adv
						} = res.data
						this.total = count
						this.cargoList = list
						this.loading = false
						this.advs = topic_adv

					}).catch(res => {
						console.log(res, '22-1')
					});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.menus {
		display: flex;
		background: #fff;
		height: 50px;
		line-height: 50px;
	}

	.menus>div {
		width: 15%;
		text-align: center;
	}

	.menus a {
		display: inline-block;
	}

	.menus .on {
		color: #5986f0;
		border-bottom: 1px solid red;
	}

	.goods-list {

		padding: 30px 0;
	}


	.list-wrap {
		overflow: hidden;
	}


	.goods-info {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 220px;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee;
			padding-top: 10px;
			position: relative;
			padding-bottom: 5px;
			background: #fff;

			&:nth-child(5n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 198px;
				height: 198px;
				cursor: pointer;
				padding: 10px;
			}

			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 50px;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
			}

			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}

	.empty {
		margin-top: 45px;
	}

	.pager {
		text-align: center !important;
		padding: 30px 0;
	}
</style>